@use 'src/styles/core' as *;
@use 'collapse-item';

.collapse {
  $b: &;

  gap: 24px;
  display: flex;
  padding: 48px;
  outline: none;
  background: $gray-10;
  border-radius: 20px;
  flex-direction: column;

  @include media-breakpoint-down(md) {
    padding: 24px;
    border-radius: 0;
  }
  @include media-breakpoint-between(sm, md) {
    margin-inline: -#{map-get($gutter, md)};
  }
  @include media-breakpoint-down(sm) {
    margin-inline: -#{map-get($gutter, sm)};
  }
}
