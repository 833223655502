@use 'src/styles/core' as *;

.large-video {
  $b: &;

  display: grid;
  position: relative;
  text-align: center;
  background: $gray-30;
  grid-template: 'container';
  border-radius: 20px;

  @include media-breakpoint-down(sm) {
    width: 100%;
    max-width: none;
    border-radius: 0;
  }

  &__video {
    width: 100%;
    grid-area: container;
    object-fit: cover;
    border-radius: inherit;
    pointer-events: none;

    @include media-breakpoint-between(lg, xl) {
      min-height: 626px;
    }
    @include media-breakpoint-between(md, lg) {
      min-height: 600px;
    }
    @include media-breakpoint-between(sm, md) {
      min-height: 450px;
    }
    @include media-breakpoint-down(sm) {
      min-height: 360px;
    }
  }

  &__inner {
    display: flex;
    padding: 24px;
    grid-area: container;
    align-items: flex-end;

    @include media-breakpoint-down(sm) {
      padding-inline: map-get($gutter, sm);
    }
  }

  &__caption {
    color: $gray-10;
    width: fit-content;
    margin: 0 auto;
    padding: 16px;
    font-size: 20px;
    background: $gray-80;
    text-align: center;
    font-weight: 700;
    line-height: 1.3;
    border-radius: 8px;

    a {
      text-underline-offset: 2px;
      text-decoration-thickness: 2px;

      @include media-hover {
        color: $primary-color;
        text-decoration-color: transparent;
      }
    }
  }
}
