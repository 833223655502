@use 'src/styles/core' as *;

.collapse-item {
  $b: &;

  display: flex;
  flex-direction: column;

  &__head {
    gap: 20px;
    display: flex;
    outline: none;
    align-items: center;

    @include focus-visible {
      #{$b}__btn {
        outline-color: $gray-50;
      }
    }

    @include media-hover {
      #{$b}__btn {
        color: $gray-10;
        background: $gray-80;
      }
    }
  }

  &__btn {
    gap: 10px;
    color: $gray-80;
    border: 1px solid $gray-80;
    display: flex;
    padding: 12px;
    border-radius: $border-radius-md;
    transition-property: color, outline, background;

    outline: {
      color: transparent;
      width: 1px;
      style: solid;
      offset: 3px;
    };

    @include media-hover {
      color: $gray-10;
      background: $gray-80;
    }
  }

  &__name {
    margin: 0;
  }

  &__body {
    display: none;
    transform: translateZ(0);
  }

  &__content {
    gap: 4px;
    display: flex;
    padding: 0 0 24px 70px;
    align-items: flex-start;
    border-bottom: 1px solid transparent;
    flex-direction: column;
    transition-property: border;

    @include media-breakpoint-down(md) {
      padding: 24px 0;
    }
  }

  &_open {
    #{$b}__btn {
      color: $gray-10;
      background: $gray-80;

      &::after {
        mask-image: url('/src/uploads/next/assets/icons/minus.svg');
      }
    }

    #{$b}__body {
      display: block;
    }

    #{$b}__content {
      border-color: $gray-30;
      transition-delay: 0.4s;
    }
  }
}
